export const generateToken = async () => {
  const response = await fetch(
    process.env.VUE_APP_SERVER_URL + "/generate_token.php",
    {
      method: "POST",
      credentials: "same-origin",
    }
  );

  const body = await response.json();
  return body;
};
