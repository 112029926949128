export const masterJobs = [
  { index: 1, id: "job1", label: "キッチンスタッフ" },
  { index: 2, id: "job2", label: "ホール・サービススタッフ" },
  { index: 3, id: "job3", label: "店長・マネジャー職" },
  { index: 4, id: "job4", label: "料理長・シェフ" },
  { index: 5, id: "job5", label: "エリアマネジャー" },
  { index: 6, id: "job6", label: "寿司職人" },
  { index: 7, id: "job7", label: "鮮魚加工" },
  { index: 8, id: "job8", label: "精肉加工" },
  { index: 9, id: "job9", label: "パティシエ" },
  { index: 10, id: "job10", label: "ブーランジェ" },
  { index: 11, id: "job11", label: "給食" },
];

export const masterPrefectures = [
  { id: "", label: "選択してください" },
  { id: 1, label: "北海道" },
  { id: 2, label: "青森県" },
  { id: 3, label: "岩手県" },
  { id: 4, label: "宮城県" },
  { id: 5, label: "秋田県" },
  { id: 6, label: "山形県" },
  { id: 7, label: "福島県" },
  { id: 8, label: "茨城県" },
  { id: 9, label: "栃木県" },
  { id: 10, label: "群馬県" },
  { id: 11, label: "埼玉県" },
  { id: 12, label: "千葉県" },
  { id: 13, label: "東京都" },
  { id: 14, label: "神奈川県" },
  { id: 15, label: "新潟県" },
  { id: 16, label: "富山県" },
  { id: 17, label: "石川県" },
  { id: 18, label: "福井県" },
  { id: 19, label: "山梨県" },
  { id: 20, label: "長野県" },
  { id: 21, label: "岐阜県" },
  { id: 22, label: "静岡県" },
  { id: 23, label: "愛知県" },
  { id: 24, label: "三重県" },
  { id: 25, label: "滋賀県" },
  { id: 26, label: "京都府" },
  { id: 27, label: "大阪府" },
  { id: 28, label: "兵庫県" },
  { id: 29, label: "奈良県" },
  { id: 30, label: "和歌山県" },
  { id: 31, label: "鳥取県" },
  { id: 32, label: "島根県" },
  { id: 33, label: "岡山県" },
  { id: 34, label: "広島県" },
  { id: 35, label: "山口県" },
  { id: 36, label: "徳島県" },
  { id: 37, label: "香川県" },
  { id: 38, label: "愛媛県" },
  { id: 39, label: "高知県" },
  { id: 40, label: "福岡県" },
  { id: 41, label: "佐賀県" },
  { id: 42, label: "長崎県" },
  { id: 43, label: "熊本県" },
  { id: 44, label: "大分県" },
  { id: 45, label: "宮崎県" },
  { id: 46, label: "鹿児島県" },
  { id: 47, label: "沖縄県" },
];

export const masterCalendarDays = [
  { index: "", label: "" },
  { index: 1, label: "1" },
  { index: 2, label: "2" },
  { index: 3, label: "3" },
  { index: 4, label: "4" },
  { index: 5, label: "5" },
  { index: 6, label: "6" },
  { index: 7, label: "7" },
  { index: 8, label: "8" },
  { index: 9, label: "9" },
  { index: 10, label: "10" },
  { index: 11, label: "11" },
  { index: 12, label: "12" },
  { index: 13, label: "13" },
  { index: 14, label: "14" },
  { index: 15, label: "15" },
  { index: 16, label: "16" },
  { index: 17, label: "17" },
  { index: 18, label: "18" },
  { index: 19, label: "19" },
  { index: 20, label: "20" },
  { index: 21, label: "21" },
  { index: 22, label: "22" },
  { index: 23, label: "23" },
  { index: 24, label: "24" },
  { index: 25, label: "25" },
  { index: 26, label: "26" },
  { index: 27, label: "27" },
  { index: 28, label: "28" },
  { index: 29, label: "29" },
  { index: 30, label: "30" },
  { index: 31, label: "31" },
];

export const masterCalendarMonths = [
  { index: "", label: "" },
  { index: 1, label: "1" },
  { index: 2, label: "2" },
  { index: 3, label: "3" },
  { index: 4, label: "4" },
  { index: 5, label: "5" },
  { index: 6, label: "6" },
  { index: 7, label: "7" },
  { index: 8, label: "8" },
  { index: 9, label: "9" },
  { index: 10, label: "10" },
  { index: 11, label: "11" },
  { index: 12, label: "12" },
];

export const masterCalendarYears = [
  { index: "", label: "" },
  { index: 1960, label: "1960" },
  { index: 1961, label: "1961" },
  { index: 1962, label: "1962" },
  { index: 1963, label: "1963" },
  { index: 1964, label: "1964" },
  { index: 1965, label: "1965" },
  { index: 1966, label: "1966" },
  { index: 1967, label: "1967" },
  { index: 1968, label: "1968" },
  { index: 1969, label: "1969" },
  { index: 1970, label: "1970" },
  { index: 1971, label: "1971" },
  { index: 1972, label: "1972" },
  { index: 1973, label: "1973" },
  { index: 1974, label: "1974" },
  { index: 1975, label: "1975" },
  { index: 1976, label: "1976" },
  { index: 1977, label: "1977" },
  { index: 1978, label: "1978" },
  { index: 1979, label: "1979" },
  { index: 1980, label: "1980" },
  { index: 1981, label: "1981" },
  { index: 1982, label: "1982" },
  { index: 1983, label: "1983" },
  { index: 1984, label: "1984" },
  { index: 1985, label: "1985" },
  { index: 1986, label: "1986" },
  { index: 1987, label: "1987" },
  { index: 1988, label: "1988" },
  { index: 1989, label: "1989" },
  { index: 1990, label: "1990" },
  { index: 1991, label: "1991" },
  { index: 1992, label: "1992" },
  { index: 1993, label: "1993" },
  { index: 1994, label: "1994" },
  { index: 1995, label: "1995" },
  { index: 1996, label: "1996" },
  { index: 1997, label: "1997" },
  { index: 1998, label: "1998" },
  { index: 1999, label: "1999" },
  { index: 2000, label: "2000" },
  { index: 2001, label: "2001" },
  { index: 2002, label: "2002" },
  { index: 2003, label: "2003" },
  { index: 2004, label: "2004" },
  { index: 2005, label: "2005" },
  { index: 2006, label: "2006" },
  { index: 2007, label: "2007" },
  { index: 2008, label: "2008" },
  { index: 2009, label: "2009" },
  { index: 2010, label: "2010" },
  { index: 2011, label: "2011" },
  { index: 2012, label: "2012" },
  { index: 2013, label: "2013" },
  { index: 2014, label: "2014" },
  { index: 2015, label: "2015" },
  { index: 2016, label: "2016" },
  { index: 2017, label: "2017" },
  { index: 2018, label: "2018" },
  { index: 2019, label: "2019" },
  { index: 2020, label: "2020" },
  { index: 2021, label: "2021" },
  { index: 2022, label: "2022" },
];
