<template>
  <div>
    <div class="cf-fv">
      <h1 class="cf-fv__logo">
        <img src="/img/fv_logo.png" alt="飲食業界の転職ならクックフラット" />
      </h1>
      <p class="cf-fv__text">
        <img src="/img/fv_content_220122.png" alt="あなたの希望叶えます" />
      </p>
      <p class="cf-fv__badge">
        <img src="/img/fulltime_badge.png" alt="正社員転職" />
      </p>
    </div>
    <div id="cf_form" class="cf-block">
      <h2 class="cf-block__title01">お気軽にご相談ください</h2>
      <form id="form" @submit="onSubmit" :validation-schema="schema">
        <div class="cf-form">
          <label for="name" class="cf-form__title"
            ><span class="cf-form__must">【必須】</span>お名前</label
          >
          <input
            type="text"
            name="name"
            id="name"
            v-model="name"
            placeholder=""
            class="cf-input-text"
            :invalid="!errors['form.name']"
            required
          />
          <p class="error_text cf-error__text">{{ errors["form.name"] }}</p>
          <span class="cf-form__title"
            ><span class="cf-form__must">【必須】</span>生年月日</span
          >
          <select id="birth_year" v-model="birth_year" required>
            <option
              v-for="year in calendar_years"
              :key="year.index"
              :value="year.index"
            >
              {{ year.label || "-" }}
            </option></select
          ><label for="birth_year"> 年 </label>
          <select id="birth_month" v-model="birth_month" required>
            <option
              v-for="month in calendar_months"
              :key="month.index"
              :value="month.index"
            >
              {{ month.label || "-" }}
            </option></select
          ><label for="birth_month"> 月 </label>
          <select id="birth_day" v-model="birth_day" required>
            <option
              v-for="day in calendar_days"
              :key="day.index"
              :value="day.index"
            >
              {{ day.label || "-" }}
            </option></select
          ><label for="birth_day"> 日</label>
          <div class="cf-form__item">
            <p class="error_text cf-error__text cf-error__text--birth">
              {{ errors["form.birth_year"] }}
            </p>
            <p class="error_text cf-error__text cf-error__text--birth">
              {{ errors["form.birth_month"] }}
            </p>
            <p class="error_text cf-error__text cf-error__text--birth">
              {{ errors["form.birth_day"] }}
            </p>
          </div>
          <label for="tel" class="cf-form__title"
            ><span class="cf-form__must">【必須】</span>携帯電話番号</label
          >
          <input
            type="tel"
            name="tel"
            id="tel"
            v-model="tel"
            placeholder=""
            class="cf-input-text"
            required
          />
          <p class="error_text cf-error__text">{{ errors["form.tel"] }}</p>
          <label for="mail" class="cf-form__title">メールアドレス</label>
          <input
            type="text"
            name="email"
            id="email"
            v-model="email"
            placeholder=""
            class="cf-input-text"
          />
          <p class="error_text cf-error__text">{{ errors["form.email"] }}</p>
        </div>
        <p class="cf-paragraph cf-form__agreement">
          送信することで<a href="/rule/" rel="noopener" target="_blank"
            >利用規約</a
          >と<a
            href="https://rancont.co.jp/privacy-policy/"
            rel="noopener"
            target="_blank"
            >個人情報の取り扱い</a
          >に同意したうえで、当社が提供する転職支援サービスの利用申込みとなります。
        </p>
        <button
          id="send-form"
          type="submit"
          :disabled="isSubmitting"
          class="cf-btn cf-btn--submit"
        >
          同意して送信する
        </button>
      </form>
      <a
        href="tel:0120362364"
        id="cf-btn--tel-v1-1"
        class="cf-btn cf-btn--single cf-btn--tel cf-event--link"
        ><img src="/img/btn_tel.png" alt="【24時間対応】電話でお問い合わせ"
      /></a>
    </div>
    <div class="cf-block cf-case">
      <h2 class="cf-block__title01">転職成功事例</h2>
      <ul class="cf-case__wrapper">
        <li class="cf-case__item">
          <h3 class="cf-case__title">
            自分の可能性を考え、正社員にチャレンジ！
          </h3>
          <div class="cf-case__image">
            <img class="cf-case__image__ph" src="/img/case_ph04.jpg" alt="" />
            <span class="cf-case__image__segment">20代前半</span>
            <span class="cf-case__point__up"
              ><span class="cf-num cf-num--mbig">140</span>万<br />UP!!</span
            >
          </div>
          <div class="cf-case__point">
            <span class="cf-case__point__before"
              >年収<span class="cf-num cf-num--mbig">240</span>万</span
            ><span class="cf-case__point__arrow">→</span>
            <span class="cf-case__point__after"
              ><span class="cf-num cf-num--big">380</span>万</span
            >
          </div>
          <p class="cf-case__point__position">事務職→大手イタリアン外食企業</p>
          <p class="cf-paragraph">
            新卒で事務職についたものの、身体を動かすのが元々好きだったため、転職を検討していた。<br />
            学生時代に飲食経験があったので飲食業界には興味があった。未経験でもチャレンジできる求人がないかエージェントに相談したところ、熱意と接客できるイメージができたことで、大手外食企業に転職成功。現在、念願の接客業を通してお客さんの笑顔を見ることをやりがいに活躍中。
          </p>
        </li>
        <li class="cf-case__item">
          <h3 class="cf-case__title">スキルを活かした転職でキャリアアップ！</h3>
          <div class="cf-case__image">
            <img class="cf-case__image__ph" src="/img/case_ph05.jpg" alt="" />
            <span class="cf-case__image__segment">40代後半</span>
            <span class="cf-case__point__up"
              ><span class="cf-num cf-num--mbig">100</span>万<br />UP!!</span
            >
          </div>
          <div class="cf-case__point">
            <span class="cf-case__point__before"
              >年収<span class="cf-num cf-num--mbig">350</span>万</span
            ><span class="cf-case__point__arrow">→</span>
            <span class="cf-case__point__after"
              ><span class="cf-num cf-num--big">450</span>万</span
            >
          </div>
          <p class="cf-case__point__position">外食企業→大手外食チェーン企業</p>
          <p class="cf-paragraph">
            現職では接客を通してやりがいを感じていたが、会社規模が小さくキャリアアップの道が見えづらいことで転職を検討していた。収入アップと頑張りを評価してくれる企業がないかエージェントに相談したところ、1人で着付けができることを評価され、大手外食チェーン企業に転職成功。現在、接客を通して頑張りが評価され収入アップに繋がり活躍中。
          </p>
        </li>
        <li class="cf-case__item">
          <h3 class="cf-case__title">コロナ禍で逆に給与アップチャンス！</h3>
          <div class="cf-case__image">
            <img class="cf-case__image__ph" src="/img/case_ph06.jpg" alt="" />
            <span class="cf-case__image__segment">50代</span
            ><span class="cf-case__point__up"
              ><span class="cf-num cf-num--mbig">100</span>万<br />UP!!</span
            >
          </div>
          <div class="cf-case__point">
            <span class="cf-case__point__before"
              >年収<span class="cf-num cf-num--mbig">350</span>万</span
            ><span class="cf-case__point__arrow">→</span>
            <span class="cf-case__point__after"
              ><span class="cf-num cf-num--big">450</span>万</span
            >
          </div>
          <p class="cf-case__point__position">
            ファミレス業界→大手焼肉チェーン業界
          </p>
          <p class="cf-paragraph">
            親の介護をきっかけに一度飲食から離れ他業界へ転職。<br />ただ飲食業界で働く事の楽しさや、やり甲斐を忘れられず、エージェントへ相談。<br />コロナ禍で企業の採用枠が広がり、結果いままで働いてきた経験を評価してもらえる企業に転職成功。現在、収入もアップする事ができ活躍中。
          </p>
        </li>
      </ul>
    </div>
    <div class="cf-block cf-cv cf-cv--beige">
      <a
        href="#cf_form"
        id="cf-btn--web-v1-1"
        class="cf-btn cf-btn--web cf-event--link"
        ><img src="/img/btn_web.png" alt="簡単10秒【無料登録】求人を受ける"
      /></a>
      <a
        href="tel:0120362364"
        id="cf-btn--tel-v1-2"
        class="cf-btn cf-btn--tel cf-event--link"
        ><img src="/img/btn_tel.png" alt="【24時間対応】電話でお問い合わせ"
      /></a>
    </div>
    <div class="cf-block cf-strength">
      <h2 class="cf-block__title01">飲食業界特化の強み</h2>
      <ul class="cf-strength__wrapper">
        <li class="cf-strength__item">
          <h3 class="cf-strength__title">
            <img
              src="/img/strength_ttl01.png"
              alt="飲食業界専門のコンサルタントが在籍"
            />
          </h3>
          <p class="cf-paragraph">
            飲食業界出身のコンサルタント在籍だからこそ、細かい希望を叶える事ができます。
          </p>
          <p class="cf-paragraph">
            調理技術、接客サービススキル、数値管理を学びたいなど、ピンポイントの求人の紹介が可能です。
          </p>
        </li>
        <li class="cf-strength__item">
          <h3 class="cf-strength__title">
            <img src="/img/strength_ttl02.png" alt="書類通過率UP・採用率UP" />
          </h3>
          <p class="cf-paragraph">
            プロのコンサルタントが、履歴書・職務経歴書の添削や作成代行をいたします。長年培ったスキルにより、時代の変化に合わせた転職サポートが可能です。
          </p>
          <p class="cf-paragraph">
            コロナ禍以前の飲食求人も熟知しているからこそ、これからの飲食業情勢と比較したうえでの的確な求人提案、アドバイス・サポートが可能です。
          </p>
        </li>
        <li class="cf-strength__item">
          <h3 class="cf-strength__title">
            <img src="/img/strength_ttl03.png" alt="非公開求人取扱実績No.1" />
          </h3>
          <p class="cf-paragraph">
            クックフラット独自の求人多数。飲食業界専門だからこそ揃えられる質の高い求人をご案内いたします。特に関東圏の優良求人を多数所有しております。まずはお気軽にお問い合わせください。
          </p>
        </li>
      </ul>
    </div>
    <div class="cf-block cf-voice">
      <h2 class="cf-block__title02">転職者の声</h2>
      <ul class="cf-voice__wrapper">
        <li class="cf-voice__item">
          <img src="/img/voice_icon02.png" alt="" class="cf-voice__icon" />
          <h3 class="cf-voice__title">
            20代前半／ホテル調理職→大手外食企業／飲食経験2年
          </h3>
          <p class="cf-paragraph">
            調理学校を出て調理がメインだったんですけど、小さなお店だったので接客もやることも多くて接客が楽しくなってきたので接客で転職先を探していました。接客は本格的にやっていなかったので、どんな会社があうのかよくわからなかったんですが、クックフラットの担当の方から「こんなお店はどう？」と色々と提案してくれたのは本当に助かりました。それだけでなく、労働環境や人間関係、厳しい一面など包み隠さず話してくれたのが良かったです。就職活動は不安もあって緊張しますが、頼れる存在がいてくれて、すごく安心しました。
          </p>
        </li>
        <li class="cf-voice__item">
          <img src="/img/voice_icon05.png" alt="" class="cf-voice__icon" />
          <h3 class="cf-voice__title">
            40代女性／外食企業→大手外食企業／飲食経験15年
          </h3>
          <p class="cf-paragraph">
            1人だと求人の情報が少ないですし、会社に直接聞き辛いことや、給与のやり取りなどを代わりにやってもらえて、非常に助かった。前職よりも給与が増えたことや、入社して雰囲気もよく担当の方には感謝しかないです！入社した後も、何かあった時にアフターフォローしてもらえたので心強かったです。
          </p>
        </li>
        <li class="cf-voice__item">
          <img src="/img/voice_icon03.png" alt="" class="cf-voice__icon" />
          <h3 class="cf-voice__title">
            50代男性／給食会社→大手外食企業／飲食経験13年
          </h3>
          <p class="cf-paragraph">
            希望条件は当たり前で、これまでの経歴だったり、自分がやりたいこと、転職するにあたっての気持ちなど、本当によく話を聞いてもらえたと思います。いろいろな選択肢があった中で「自分自身が働きやすいと思う職場を選びましょう」と言ってくれて、営業的になりすぎない、人と人との繋がり、温かみを感じました。おかげで、働きやすい環境で楽しく働けてます。
          </p>
        </li>
      </ul>
    </div>
    <div class="cf-block cf-cv">
      <a
        href="#cf_form"
        id="cf-btn--web-v1-2"
        class="cf-btn cf-btn--web cf-event--link"
        ><img src="/img/btn_web.png" alt="簡単10秒【無料登録】求人を受ける"
      /></a>
      <a
        href="tel:0120362364"
        id="cf-btn--tel-v1-3"
        class="cf-btn cf-btn--tel cf-event--link"
        ><img src="/img/btn_tel.png" alt="【24時間対応】電話でお問い合わせ"
      /></a>
    </div>
    <div class="cf-block cf-flow">
      <h2 class="cf-block__title01">ご入社までの流れ</h2>
      <ol>
        <li class="cf-flow__item">
          <h3 class="cf-flow__title">エントリー</h3>
          <p class="cf-paragraph">
            ご登録フォームよりお気軽にエントリーください。<br />お電話でのご相談もお待ちしております。
          </p>
        </li>
        <li class="cf-flow__item">
          <h3 class="cf-flow__title">求人のご提案</h3>
          <p class="cf-paragraph">
            専任のコンサルタントが細かくヒアリングし、最適な求人をご提案いたします。
          </p>
        </li>
        <li class="cf-flow__item">
          <h3 class="cf-flow__title">面接・ご入社</h3>
          <p class="cf-paragraph">
            コンサルタントが書類の添削・面接対策を丁寧にサポートいたします。条件交渉もお任せください。
          </p>
        </li>
      </ol>
    </div>
    <div class="cf-block cf-faq">
      <h2 class="cf-block__title02">よくあるご質問</h2>
      <ul>
        <li class="cf-faq__item">
          <h3 class="cf-faq__title">
            飲食業が好きなのですが、正直コロナの影響が不安です。どのようにしたらいいですか？
          </h3>
          <p class="cf-paragraph cf-faq__answer">
            もちろん、この先の不透明な未来を予測することは困難な時代ですので、不安になるのは当然のことと思います。<br />
            弊社では、求人企業様がコロナ禍の際、具体的にどのような取り組みをしてきたか、補償はどのように対応してきたか、今後の対策、などあらゆる情報を独自に取材を行っています。<br />
            インターネットやニュース情報以外の、実際の事実を知る事で、不安は払拭できると思います。まずはお気軽にご相談ください。
          </p>
        </li>
        <li class="cf-faq__item">
          <h3 class="cf-faq__title">
            希望条件が沢山あるのですが、どのようにして希望に合う求人を紹介してくれるのでしょうか？
          </h3>
          <p class="cf-paragraph cf-faq__answer">
            電話、直接の面談、WEB面談等で、過去、現在、未来どんな仕事をしてきて、どんな不満を持ち、どこにやりがいを感じたか、今までの職歴や得意なこと等さまざま質問をさせていただきます。その上で条件に合ったお店、企業様へ売り込み、求人を獲得する事で、ご希望条件通りの企業様へ就業できるようサポートさせていただきます。
          </p>
        </li>
        <li class="cf-faq__item">
          <h3 class="cf-faq__title">
            すぐに転職を考えていませんが相談可能ですか？
          </h3>
          <p class="cf-paragraph cf-faq__answer">
            もちろん可能です。転職とは人生の一大イベントだと考えております。<br />
            だからこそ納得いくまで、とことんサポートさせていただきます。<br />
            求人や企業の情報を元に将来のことや、今学びたいことを一緒に考え、二人三脚で進んでいきましょう！
          </p>
        </li>
        <li class="cf-faq__item">
          <h3 class="cf-faq__title">
            登録することで、現在の職場に分かってしまうことはありますか？
          </h3>
          <p class="cf-paragraph cf-faq__answer">
            もちろん、一切ございません。<br />
            厚生労働省の許認可を受けているため、個人情報の保護は徹底しておりますのでご安心ください。<br />
            「転職活動中の旨や、退職意向」を現職へ伝えるタイミングもご相談しながら一緒に決めていきましょう！
          </p>
        </li>
      </ul>
      <div class="cf-block cf-cv">
        <a
          href="#cf_form"
          id="cf-btn--web-v1-3"
          class="cf-btn cf-btn--web cf-event--link"
          ><img src="/img/btn_web.png" alt="簡単10秒【無料登録】求人を受ける"
        /></a>
        <a
          href="tel:0120362364"
          id="cf-btn--tel-v1-4"
          class="cf-btn cf-btn--tel cf-event--link"
          ><img src="/img/btn_tel.png" alt="【24時間対応】電話でお問い合わせ"
        /></a>
      </div>
    </div>

    <footer class="footer">
      <div class="footer_info">
        <dl>
          <dt>運営会社</dt>
          <dd>ランコント株式会社</dd>
          <dt>所在地</dt>
          <dd>東京都千代田区東神田3-1-2 D'sVARIE東神田ビル9階</dd>
          <dt>資格</dt>
          <dd>厚生労働大臣許可 有料職業紹介業 １３-ユ-３１４２７０</dd>
          <dt>お問合先</dt>
          <dd>
            <a
              id="cf-btn--tel-v1-footer"
              class="cf-address--tel cf-event--link"
              href="tel:0120-362-364"
              >0120-362-364</a
            >
          </dd>
        </dl>
      </div>
      <div class="copy_right">
        ©2021 ランコント株式会社. All rights reserved.
      </div>
    </footer>
  </div>
</template>

<script>
import {
  masterCalendarDays,
  masterCalendarMonths,
  masterCalendarYears,
} from "../../const/master";
import { generateToken } from "../../modules/token";
import { rules00 } from "../../validation/rule";
import { useField, useForm } from "vee-validate";
export default {
  name: "Home",
  components: {},
  setup() {
    const calendar_days = masterCalendarDays;
    const calendar_months = masterCalendarMonths;
    const calendar_years = masterCalendarYears;
    let token = "";
    generateToken().then((body) => {
      token = body.token;
    });

    const { errors, meta, handleSubmit, isSubmitting } = useForm({
      validationSchema: rules00,
      initialValues: {
        form: {
          name: "",
          birth_year: "",
          birth_month: "",
          birth_day: "",
          tel: "",
          email: "",
          token: token,
        },
      },
    });

    const { value: name } = useField("form.name");
    const { value: birth_year } = useField("form.birth_year");
    const { value: birth_month } = useField("form.birth_month");
    const { value: birth_day } = useField("form.birth_day");
    const { value: tel } = useField("form.tel");
    const { value: email } = useField("form.email");

    const sendForm = async () => {
      const formData = {
        name: name.value,
        birth_year: birth_year.value,
        birth_month: birth_month.value,
        birth_day: birth_day.value,
        tel: tel.value,
        email: email.value,
        token: token,
      };

      const response = await fetch(
        process.env.VUE_APP_SERVER_URL + "/form.php",
        {
          method: "POST",
          credentials: "same-origin",
          body: JSON.stringify(formData),
          headers: new Headers({
            "Content-type": "application/json",
          }),
        }
      );

      const body = await response.json();
      return body;
    };

    const onSubmit = handleSubmit(() => {
      sendForm()
        .then(() => {
          // 成功した場合の処理
          window.location.href = "./complete/";
        })
        .catch(() => {
          // 失敗した場合の処理
          alert(
            "送信に失敗しました。お手数ですが時間をおいてから再度送信していただくか、0120-362-364(9:00～19:00)までお問い合わせください。"
          );
        });
    });

    return {
      calendar_years,
      calendar_months,
      calendar_days,
      name,
      birth_year,
      birth_month,
      birth_day,
      tel,
      email,
      token,
      errors,
      meta,
      onSubmit,
      isSubmitting,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/lp00/style.scss";
</style>
