import { object, string } from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const rules = object({
  form: object({
    job_type: string().required("必須の項目です。"),
    name: string().required("必須の項目です。"),
    birth_year: string().required("必須の項目です。"),
    birth_month: string().required("必須の項目です。"),
    birth_day: string().required("必須の項目です。"),
    prefecture: string().required("必須の項目です。"),
    tel: string()
      .required("必須の項目です。")
      .matches(phoneRegExp, "電話番号の形式ではありません。"),
    email: string()
      .required("必須の項目です。")
      .email("メールアドレスの形式ではありません。"),
  }),
});

export const rules00 = object({
  form: object({
    name: string().required("必須の項目です。"),
    birth_year: string().required("必須の項目です。"),
    birth_month: string().required("必須の項目です。"),
    birth_day: string().required("必須の項目です。"),
    tel: string()
      .required("必須の項目です。")
      .matches(phoneRegExp, "電話番号の形式ではありません。"),
    email: string().email("メールアドレスの形式ではありません。"),
  }),
});
